const state = {
  isCollapse: false,
};

const getters = {
  isCollapse: (state) => state.isCollapse,
};

const actions = {
  isCollapse({ commit }) {
    commit("isCollapse");
  },
};

const mutations = {
  isCollapse(state, parameter) {
    state.isCollapse = parameter;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
