import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/record",
  },
  {
    path: "/record",
    component: () => import("@/views"),
    children: [
      {
        path: "",
        name: "Record",
        component: () => import("@/views/record"),
      },
    ],
  },
  {
    path: "/user",
    component: () => import("@/views"),
    children: [
      {
        path: "",
        name: "User",
        component: () => import("@/views/user"),
      },
    ],
  },
  {
    path: "/wx-staff",
    component: () => import("@/views"),
    children: [
      {
        path: "",
        name: "wxStaff",
        component: () => import("@/views/wx-staff"),
      },
    ],
  },
  {
    path: "/wx-user",
    component: () => import("@/views"),
    children: [
      {
        path: "",
        name: "wxUser",
        component: () => import("@/views/wx-user"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
